<template>
    <div class="PrivacyPolicy">
        <!-- <h1>隐私政策</h1> -->
        <img src="https://img.youstarplanet.com/website/legalNotices/PrivacyPolicy.png" alt="">
        <div class="navigation" @click="CaseShow"><span>首页 > 隐私政策</span></div>
        <div class="content">
            <div>隐私政策</div>
            <p>
                上次更新时间：2021年12月1日<br>
                欢迎来到YOU STAR PLANET！ <br>
                感谢您访问youstarplanet.com！星都时代重视您的隐私，我们感谢您对星都时代的信任。<br>
                <span>本隐私政策（下称“隐私政策”）将解释对于您在youstarplanet.com 网站及其子域名（下称“网站”）上的个人信息之处理时间、方式及原因，并规定了您与该信息所持有的相关选择和权利。请仔细阅读——了解我们如何收集和使用您的信息，以及您可以如何控制这些信息非常重要。<br>
                请注意，本隐私政策仅适用于我们的网站。<br>
                关于任何星都时代产品或服务处理其客户之个人信息的方式，请参阅这些产品或服务的特定隐私政策。<br>
                关于星都时代处理其供应商之个人信息的方式，请查询相关特定隐私政策。<br>
                如果您不同意按照本隐私政策所述方式处理您的个人信息，请不要在收到请求时提供您的信息，并停止使用本网站。使用本网站即表示您确认我们在本隐私政策中所述的有关您个人信息的规定。<br>
                本网站的开发者为 深圳市星都时代文化科技有限公司（Shen Zhen You-Star-Planet Art-Tech Service Co.,Ltd）（下称“我们”或“我们的”）。</span><br>

                <br><span>1. 我们使用的个人信息类型</span><br>
                本节说明我们将向您收集不同类型的个人信息，以及我们如何收集这些信息。如果您想进一步了解特定数据类型以及我们如何使用这些数据，请参阅下面的部分“我们如何使用您的个人信息”。<br>
                以下内容总结概括了我们使用的个人信息类型：<br>
                <span>您提供给我们的信息</span><br>
                在您通过我们网站上的投资者及媒体联系页面提交问询，或使用本隐私政策中指定的联系信息联系我们时，您即向我们提供查询相关的特定信息。<br>
                <span>Cookie</span><br>
                我们使用 Cookie（下称“Cookie”）来提升您的网站使用体验。Cookie 是一种文本文件，当放置在您的设备上时，能够让我们提供特定的特性和功能。<br>
                关于我们 Cookie 政策的更多信息，请单击此处。<br>

                <br><span>2. 儿童</span><br>
                <span>儿童不得出于任何目的使用本网站，除非父母或监护人已提供同意。<br>
                我们所指的儿童为 18 周岁以下的用户。<br>
                我们不会出于任何目的故意收集儿童的个人信息。如果您认为我们在未经父母/监护人同意的情况下拥有儿童的个人信息，或者您是用户的父母或监护人并希望撤回同意，请通过privacypolicy@youstarplanet.com与我们联系，我们将会删除此类信息。</span><br>

                <br><span>3. 我们如何存储和共享您的个人信息</span><br>
                我们公司的业务可能拓展至全球范围，您的个人信息将由我们位于中华人民共和国 (PRC) 的服务器处理。<br>
                无论我们的服务器位于何处，我们都会根据本隐私政策采取适当措施，保护您的权利。<br>
                <span>我们仅在合理必要的情况下与第三方共享您的个人信息。这类情况包括：<br>
                提供服务以支持本网站的第三方（包括云服务提供商），这些提供商为支持与您的问询相关的通讯而处理本政策中所明确的信息。除向我们提供服务外，所有向我们提供服务的公司均不得出于任何目的保留、使用或披露您的个人信息。<br>
                我们公司为回应您的问询而处理您的个人信息，且只能根据本隐私政策使用您的个人信息。<br>
                监管机构、司法机关和执法机构，以及出于安全、保障或遵守法律之目的的其他第三方。在一些情况下，我们依法须向当局披露您的信息，例如遵守法律义务或程序、强制实施我们的条款、处理与安全或欺诈相关的事宜，或者保护我们的用户。为遵守有效的法律程序条款，如传票、法院命令或搜查令，我们可能在获得或未获得您同意的情况下做出这些披露。一般而言，法律程序的条款禁止我们通知您任何此类披露。如果政府实体未提供所需的传票、法院命令或搜查令，则我们可能会征求您的同意，以按照政府实体的要求披露信息。<br>
                我们还可能出于以下目的披露您的信息：<br>
                <span class="div1">（1） 强制实施我们的条款和条件及其他协议，包括调查任何可能违反这些文件的行为；</span>
                <span class="div1">（2） 发现、防止或以其他方式解决安全、欺诈或技术问题；</span>
                <span class="div1">（3） 或在法律要求或允许的情况下，保护我们、我们的用户、第三方或公众的权利、财产或安全（为了防止欺诈和降低信用风险而与其他公司和机构交换信息）。</span>
                <span class="div1">（4） 全部或部分收购我们或我们的业务的第三方。如果出现任意下述情况，我们也可能会向第三方披露您的个人信息：</span>
                    <span class="div2">(a) 我们出售、转让、合并、整合或重组业务中的任何部分，或与任何其他企业合并、收购任何其他企业或与其组建合资企业，则我们可能会将您的数据披露给任何潜在买家、新所有者或其他参与我们业务变更的第三方；</span>
                    <span class="div2">(b) 我们出售或转让我们的任何资产，则我们持有的与您有关的信息可能会作为该等资产的一部分出售，并可能会转让给任何潜在买家、新所有者或其他参与该等出售或转让的第三方。</span></span>

                <br><span>4. 个人信息的安全性</span><br>
                无论您的个人信息存储在何处，我们均承诺维护其隐私和完整性。我们的信息安全与访问政策限制对我们的系统和技术进行访问，我们通过使用加密等技术保护措施来保护数据。<br>
                <span>遗憾的是，通过互联网传输信息并不完全安全。尽管我们将实施并保持合理措施来保护您的个人信息，但我们不能保证通过本网站或以其他方式通过互联网传输的信息的安全性；对于任何信息传输，风险均由您自行承担。</span><br>

                <br><span>5. 数据保留</span><br>
                除非法律有所要求，否则我们保留您的数据的时间不会超过授权期间及数据留存所需的期限。我们将按照以下方式保留您的信息：<br>
                <span>您提交查询表格或使用本隐私政策中指定的详细信息联系我们时向我们提供的信息：直至您的查询得到解决，然后在解决后的一百八十 (180) 天内删除该信息，除非您同意保留这些数据并在将来接收更多信息。</span><br>
                如果需要我们在上述规定之保留期限之后保留您的信息，例如为了遵守适用法律，我们会将其与其他类型的个人信息分开存储。<br>

                <br><span>6. 您的权利</span><br>
                您拥有我们持有的有关您的个人信息的相关法定权利（在适用法律和法规允许范围内）。您可以请求访问或更正我们所处理的有关您的数据。我们可能会因遵守数据访问请求而向您收取一定的合理费用。<br>
                如要行使您的任何权利，请通过privacypolicy@youstarplanet.com与我们联系。<br>
                请注意，本网站不涉及任何个性化广告。任何电子邮件仅为回应您提交的查询而发送，并且仅为服务消息。<br>

                <br><span>7. 联系和投诉</span><br>
                欢迎就本隐私政策提出问题、意见和请求。出于遵守适用隐私法的目的，请向指定的数据保护官提出此类反馈，其联系方式为 privacypolicy@youstarplanet.com。<br>
                如果您希望就我们处理个人信息的方式提起投诉，请首先通过 privacypolicy@youstarplanet.com与我们联系，我们将努力尽快处理您的请求。<br>

                <br><span>8. 变更</span><br>
                如果本隐私政策有任何变更，我们将在此发布更新的隐私政策。请定期访问本页面，查看本隐私政策是否有任何更新或变更。<br>
            </p>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return { }
    },
    methods: {
        CaseShow(){
            this.$router.push({path:`/`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.PrivacyPolicy{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 75px auto 200px;
        padding: 0 80px;
        div{
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 55px;
        }
        &>p{
            font-size: 16px;
            line-height: 35px;
            span{
                font-weight: bold;
            }
            .cssx{
                font-weight: normal;
                color: #000000;
            }
            .div1{
                display: block;
                padding: 0 12px; 
            }
            .div2{
                display: block;
                padding: 0 50px;
            }
        }
    }
}
</style>